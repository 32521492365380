import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import { CREATE_SUSPENSION } from 'src/components/players/players-queries';
import Spinner from 'src/components/spinner/spinner';
import SuspensionForm from 'src/components/suspension-form/suspension-form';
import { usePlayerByExternalId } from 'src/hooks/data/suspensions';
import { NavigationState } from 'src/utils/typedefs/navigation';

import { H2 } from '@clubspark-react/clubspark-react-tools';

interface Props {
  id: string;
}

const initialValues = {
  startDate: new Date(),
  endDate: dayjs().add(3, 'months').toDate(),
  comment: '',
  violations: [],
};

const SuspendPlayer: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { fullName, loading: loadingPlayer } = usePlayerByExternalId(id);

  const [createSuspension, { loading, error }] = useMutation(CREATE_SUSPENSION, {
    client: meshGatewayClient,
  });

  const [selectedViolations, setSelectedViolations] = useState<any>([]);

  const editSelectedPoints = (id, checked) => {
    let selected = selectedViolations;
    if (checked === true) {
      selected.push(id);
    } else {
      selected = selected.filter((i) => i !== id);
    }
    setSelectedViolations(selected);
  };

  const handleSubmit = (values) => {
    const input = Object.assign(values, {
      externalId: id,
      violations: selectedViolations,
    });
    createSuspension({
      variables: {
        input: input,
      },
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: t('player suspended shorthand'),
          },
        };
        navigate(`/players/${id}?tab=suspensions`, { state });
      },
    });
  };

  return loadingPlayer ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${id}` },
          { name: t('add suspension'), active: true },
        ]}
      />
      <H2 spacing={{ margins: { sm: 'top' } }}>
        {t('suspend')} {fullName}
      </H2>
      <SuspensionForm
        id={id}
        formVals={initialValues}
        loading={loading}
        submitError={error}
        handleSubmit={handleSubmit}
        editSelectedPoints={editSelectedPoints}
      />
    </>
  );
};

export default SuspendPlayer;
