import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import SuspendPlayer from 'src/components/suspend-player/suspend-player';

const PlayerProfileSuspendPlayerPage = () => {
  return (
    <Router basepath="/players/:id">
      <PlayerProfileSuspendPlayer path="/suspend-player" />
    </Router>
  );
};

interface PlayerProfileSuspendPlayerRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileSuspendPlayer: React.FC<PlayerProfileSuspendPlayerRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Suspend player" />
      <PrivateRoute>
        <SuspendPlayer id={id} />
      </PrivateRoute>
    </Layout>
  );
};

export default PlayerProfileSuspendPlayerPage;
